import React, { useMemo } from "react";
import {
  ExcelExportColumn,
  ExcelExport,
} from "@progress/kendo-react-excel-export";
import { useTranslation } from "react-i18next";
import { usePaymentPageStoreState } from "store/PaymentPageStore";
import { formatDateWithTime } from "utils/pdf";
import { useAppStoreState } from "store/AppStore";
import { paymentColumnConfig } from "./paymentsColumnConfig";
import { TFunction } from "i18next";
import moment from "moment";

type Props = {};

export const ExportExcel = React.forwardRef<ExcelExport, Props>(
  (props, ref) => {
    const { t } = useTranslation();
    const cols = paymentColumnConfig;
    const actualColumns = cols.filter(
      (c) =>
        c.columnId !== "ordinalNumber" &&
        c.columnId !== "select" &&
        c.columnId !== "paymentoptions" &&
        c.columnId !== "lastModified" &&
        c.columnId !== "declared"
    );
    const { taxonomy } = useAppStoreState();
    const { payments } = usePaymentPageStoreState();

    const data = useMemo(() => {
      return transformRowsForExcel({
        rows: payments.map((payment: any) => {
          return {
            ...payment,
            clientName:
              payment.clientName === null
                ? payment.clientLastName +
                  " " +
                  payment.clientFirstName +
                  " (" +
                  payment.clientCode +
                  ")"
                : payment.clientName + " (" + payment.clientCode + ")",
          };
        }),
        columns: actualColumns,
        t,
        taxonomy,
      });
    }, [actualColumns, payments, taxonomy]);

    return (
      <ExcelExport
        data={data}
        ref={ref}
        fileName={`Payments ${formatDateWithTime(new Date())}.xlsx`}
      >
        {actualColumns.map((col) => {
          // if (col.excel && col.excel.isVisible === false) return null;
          // let width = col.width;
          // if (col.excel && col.excel.width !== undefined) {
          //   width = col.excel.width;
          // }
          // //console.log(col);
          // let format = null;
          // if (col.filter) {
          //   const filterType = col.filter.type;
          //   if (filterType === "date") {
          //     format = "{0:dd/MM/yyyy}";
          //   } // This format is for displaying in the grid.
          //   else if (filterType === "number") {
          //     format = "#,##0.00";
          //   } else if (filterType === "boolean") {
          //     format = "Yes/No";
          //   } else if (filterType === "taxonomy") {
          //     format = "string";
          //   } else if (filterType === "text") {
          //     format = "string";
          //   }
          // }
          let format = null;
          let align: "center" | "left" | "right" = "center";
          if (col.cellType === "customtext") {
            format = "text";
            align = "left";
          } else if (col.cellType === "customnumber") {
            format = "#,##0.00";
            align = "right";
          } else if (col.cellType === "datepicker") {
            format = "dd/MM/yyyy";
            align = "center";
          } else if (col.cellType === "taxonomy") {
            format = "text";
            align = "left";
          } else if (col.cellType === "customcheckmark") {
            format = "text";
            align = "left";
          }
          return (
            <ExcelExportColumn
              key={col.columnId}
              field={col.columnId}
              title={t(col.label)}
              cellOptions={{ format: format }}
              width={col.width}
              headerCellOptions={{
                background: "#005aa1",
                color: "#fff",
                textAlign: align,
              }}
            />
          );
        })}
      </ExcelExport>
    );
  }
);

export const transformRowsForExcel = ({
  rows,
  columns,
  taxonomy,
  t,
}: {
  rows: any[];
  columns: any[];
  taxonomy: any;
  t: TFunction;
}) => {
  if (!rows) return [];

  return rows.map((v) => {
    return columns.reduce((transformRow, c) => {
      if (c.cellType === "customtext") {
        transformRow[c.columnId] = v[c.columnId];
      } else if (c.cellType === "customnumber") {
        transformRow[c.columnId] = v[c.columnId];
      } else if (c.cellType === "datepicker") {
        transformRow[c.columnId] = v[c.columnId]
          ? moment(v[c.columnId]).format("DD.MM.YYYY")
          : "";
      } else if (c.cellType === "taxonomy") {
        transformRow[c.columnId] = t(
          taxonomy[c.additionalInfo.taxonomy].byId[v[c.columnId]].code
        );
      } else if (c.cellType === "customcheckmark") {
        transformRow[c.columnId] = v[c.columnId]
          ? t("greco.yes")
          : t("greco.no");
      }
      return transformRow;
    }, {});
  });
};
