import Axios, { AxiosError } from "axios";
import moment from "moment";
import { Setting } from "types/types";
import {
  formatNumberForSearch,
  getShortInsurerName,
  handleAxiosError,
  logWithTimestamp,
  prepareNotificationObject,
} from "utils/utils";
import * as API from "../api/api";
import { StoreState } from "./PaymentPageStore";
import { Payment, PaymentsMetadataMap } from "utils/types";
import { setNotificationMessage } from "./AppStoreActions";

export const setStoreValue =
  (name: keyof StoreState, value) =>
  ({ setState }) => {
    setState({
      [name]: value,
    });
  };

export const setStoreValues =
  (values) =>
  ({ setState }) => {
    setState({
      ...values,
    });
  };

export const loadPayments =
  (grecoCompanyNumbers) =>
  async ({ setState, getState, dispatch }) => {
    try {
      if (getState().paymentsLoadStatus === "loading") return;
      setState({
        paymentsLoadStatus: "loading",
      });
      // logWithTimestamp("loadPayments before");
      // const startTime = new Date().getTime();
      const response = await API.paymentQuery({
        ClientIds: getState().searchClientsSelectedItem?.value?.id
          ? [getState().searchClientsSelectedItem?.value?.id]
          : [],
        InsurerIds: getState().searchInsurersSelectedItem?.value?.id
          ? [getState().searchInsurersSelectedItem?.value?.id]
          : [],
        From: getState().fromDateFilter
          ? moment(getState().fromDateFilter).format("YYYY-MM-DD")
          : null,
        To: getState().toDateFilter
          ? moment(getState().toDateFilter).format("YYYY-MM-DD")
          : null,
        ServiceSegmentCodes: getState().searchServiceSegmentItem
          ? [getState().searchServiceSegmentItem]
          : [],
        IsBrokerPremiumCollection: false,
        CurrencyCode: Number(getState().searchCurrencyItem),
        PolicyNumbers:
          getState().searchPolicyNumber && getState().searchPolicyNumber !== ""
            ? [getState().searchPolicyNumber]
            : [],
        PolicySubnumbers: [],
        DateFilterField: getState().searchDateFilterField,
        GrecoCompanyNumbers: grecoCompanyNumbers
          ?.filter((company: any) => company.selected)
          .map((company: any) => company.companyNumber),
        IsDiscountQuery:
          getState().searchIsDiscountPaidItem === "yes"
            ? true
            : getState().searchIsDiscountPaidItem === "no"
            ? false
            : null,
        ExcludeDeclaredPayments: getState().excludeDeclaredPayments,
      });
      // const endTime = new Date().getTime();
      // logWithTimestamp("loadPayments after " + (endTime - startTime) + "ms");
      let payments = response.data
        .filter((el) => el.isDeleted === false)
        .map((el) => {
          return {
            ...el,
            insurerNameShort:
              getShortInsurerName(el.insurerId + "") === ""
                ? el.insurerName
                : getShortInsurerName(el.insurerId + "") +
                  " (" +
                  el.insurerInternalNumber +
                  ")",
          };
        });
      // if (getState().searchIsDiscountPaidItem !== "all") {
      //   payments = payments.filter(
      //     (el) =>
      //       el.isDiscountPayment ===
      //       (getState().searchIsDiscountPaidItem === "yes")
      //   );
      // }
      let paymentsMetadataMap: PaymentsMetadataMap = {};

      payments.forEach((statement) => {
        statement.clientName =
          (statement.clientName
            ? statement.clientName
            : " " +
              statement.clientLastName +
              " " +
              statement.clientFirstName) +
          " (" +
          statement.clientInternalNumber +
          ")";
        paymentsMetadataMap[statement.paymentId] = {
          selected: false,
          inEditMode: false,
          changes: [],
          deleted: statement.isDeleted,
          editable: false,
          visible: true,
          oldRow: null,
        };
      });

      setState({
        payments,
        paymentsMetadataMap,
        paymentsLoadStatus: "success",
        paymentsSelectAll: false,
      });
      // onSuccess && onSuccess(response.headers["usertype"]);
    } catch (error: any) {
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("loadPayments", message)
            )
          );
        });
        setState({
          paymentsLoadStatus: "error",
        });
      }
    }
  };

export const filterPayments =
  (payload) =>
  ({ setState, getState, dispatch }) => {
    const { searchTerm } = payload;
    let payments = getState().payments as Payment[];
    let paymentsMetadataMap = {
      ...getState().paymentsMetadataMap,
    } as PaymentsMetadataMap;
    payments.forEach((el) => {
      let isMatch = true;
      if (searchTerm && searchTerm !== "") {
        isMatch =
          isMatch &&
          (el.insurerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            el.policyName
              ?.toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            el.policyNumber
              ?.toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            el.policySubNumber
              ?.toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            el.reminderNumber
              ?.toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            el.clientName
              ?.toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            el.clientFirstName
              ?.toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            el.clientLastName
              ?.toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            el.paymentComment
              ?.toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            el.insurerName
              ?.toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            formatNumberForSearch(el.premiumAmount).includes(
              searchTerm.toLowerCase()
            ) ||
            formatNumberForSearch(el.commissionAmount).includes(
              searchTerm.toLowerCase()
            ) ||
            formatNumberForSearch(el.bookedPremiumAmount).includes(
              searchTerm.toLowerCase()
            ) ||
            (el.paymentDate
              ? moment(el.paymentDate).format("DD.MM.YYYY")
              : ""
            ).includes(searchTerm.toLowerCase()) ||
            (el.declaredDate
              ? moment(el.declaredDate).format("DD.MM.YYYY")
              : ""
            ).includes(searchTerm.toLowerCase()) ||
            (el.createdAt
              ? moment(el.createdAt).format("DD.MM.YYYY")
              : ""
            ).includes(searchTerm.toLowerCase()) ||
            (el.bookingDate
              ? moment(el.bookingDate).format("DD.MM.YYYY")
              : ""
            ).includes(searchTerm.toLowerCase()));
      }

      paymentsMetadataMap[el.paymentId].visible = isMatch;
    });
    // console.log(
    //   "IN " + searchTerm,
    //   "visible paymentsMetadataMap",
    //   Object.values(paymentsMetadataMap).reduce((acc: number, obj: any) => {
    //     return obj.visible === true ? acc + 1 : acc;
    //   }, 0)
    // );
    setState({
      paymentsMetadataMap: paymentsMetadataMap,
    });
  };

export const loadPaymentPlansForReport =
  (payload) =>
  async ({ setState, getState, dispatch }) => {
    const { onSuccess, onError, grecoCompanyNumbers } = payload;
    const cancelSource = Axios.CancelToken.source();

    try {
      if (getState().paymentsLoadStatus === "loading") return;
      setState({
        paymentsLoadStatus: "loading",
      });

      const response = await API.paymentQuery({
        ClientIds: getState().searchClientsSelectedItem?.value?.id
          ? [getState().searchClientsSelectedItem?.value?.id]
          : [],
        InsurerIds: getState().searchInsurersSelectedItem?.value?.id
          ? [getState().searchInsurersSelectedItem?.value?.id]
          : [],
        From: getState().fromDateFilter
          ? moment(getState().fromDateFilter).format("YYYY-MM-DD")
          : null,
        To: getState().toDateFilter
          ? moment(getState().toDateFilter).format("YYYY-MM-DD")
          : null,
        ServiceSegmentCodes: getState().searchServiceSegmentItem
          ? [getState().searchServiceSegmentItem]
          : [],
        IsBrokerPremiumCollection: false,
        CurrencyCode: Number(getState().searchCurrencyItem),
        PolicyNumbers:
          getState().searchPolicyNumber && getState().searchPolicyNumber !== ""
            ? [getState().searchPolicyNumber]
            : [],
        PolicySubnumbers: [],
        GrecoCompanyNumbers: grecoCompanyNumbers
          ?.filter((company: any) => company.selected)
          .map((company: any) => company.companyNumber),
        ExcludeDeclaredPayments: getState().excludeDeclaredPayments,
      });
      let payments = response.data.filter((el) => el.isDeleted === false);
      let paymentsMetadataMap: PaymentsMetadataMap = {};

      payments.forEach((statement) => {
        paymentsMetadataMap[statement.paymentId] = {
          selected: false,
          inEditMode: false,
          changes: [],
          deleted: statement.isDeleted,
          editable: false,
          visible: true,
          oldRow: null,
        };
      });

      setState({
        payments,
        paymentsMetadataMap,
        paymentsLoadStatus: "success",
      });
      // onSuccess && onSuccess(response.headers["usertype"]);
    } catch (error: any) {
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("loadPaymentPlansForReport", message)
            )
          );
        });
        setState({
          paymentsLoadStatus: "error",
        });
      }
    }
  };

export const invalidatePayment =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().invalidatePaymentsStatus === "loading") return;
    try {
      setState({
        invalidatePaymentsStatus: "loading",
      });
      const res = await API.invalidatePayments(payload);
      setState({
        invalidatePaymentsStatus: "success",
      });
      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("invalidatePayment", message)
          )
        );
      });
      setState({
        invalidatePaymentsStatus: "error",
      });
      onError && onError(error);
    }
  };

export const loadSettings =
  (applicationCodeId) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.getAppSettings(applicationCodeId);
      setState({
        settings: res.data,
        settingsLoadStatus: "success",
      });
      const defaultView = res.data.find((s) => s.isDefault);
      if (defaultView) {
        setState({
          selectedViewId: defaultView.userAppSettingId,
        });
      }
    } catch (err) {
      handleAxiosError(err, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("loadSettings", message)
          )
        );
      });
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

export const searchClients =
  (payload) =>
  async ({ setState, getState, dispatch }) => {
    const { onSuccess, onError, searchTerm } = payload;
    const cancelSource = Axios.CancelToken.source();
    try {
      if (getState().clientsLoadStatus === "loading") return;
      setState({
        searchClientsLoadStatus: "loading",
      });
      const response = await API.searchClients(searchTerm);
      setState({
        searchClientsList: response.data,
        searchClientsLoadStatus: "success",
      });
      onSuccess && onSuccess(response.data);
    } catch (error: any) {
      onError && onError(error);
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("searchClients", message)
            )
          );
        });
        setState({
          searchClientsLoadStatus: "error",
        });
      }
    }
  };

export const searchInsurers =
  (payload) =>
  async ({ setState, getState, dispatch }) => {
    const { onSuccess, onError, searchTerm } = payload;
    const cancelSource = Axios.CancelToken.source();
    try {
      if (getState().insurersLoadStatus === "loading") return;
      setState({
        searchInsurersLoadStatus: "loading",
      });
      const response = await API.searchInsurers(searchTerm);
      setState({
        searchInsurersList: response.data,
        searchInsurersLoadStatus: "success",
      });
      onSuccess && onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      onError && onError(error);
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("searchInsurers", message)
            )
          );
        });
        setState({
          searchInsurersLoadStatus: "error",
        });
      }
    }
  };

export const createSetting =
  (newSetting: Setting) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.addAppSetting(newSetting);
      const allSettings = res.data;
      setState({
        settings: allSettings,
        settingsLoadStatus: "success",
      });
      const newSettingId = allSettings.find(
        (s) => s.userAppSettingName === newSetting.userAppSettingName
      )?.userAppSettingId;
      if (newSettingId) {
        setState({
          selectedViewId: newSettingId,
        });
      }
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("createSetting", message)
          )
        );
      });
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

export const deleteSetting =
  (id: number) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.deleteAppSetting(id);
      const allSettings = res.data;
      setState({
        settings: allSettings,
        settingsLoadStatus: "success",
      });
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("deleteSetting", message)
          )
        );
      });
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

export const updateSetting =
  (setting: Setting) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.updateAppSetting(setting);
      const allSettings = res.data;
      setState({
        settings: allSettings,
        settingsLoadStatus: "success",
      });
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("updateSetting", message)
          )
        );
      });
      setState({
        settingsLoadStatus: "error",
      });
    }
  };
